body, .App, #root{
    margin:0;
    padding: 0;
    font-size: 15px;
    background-color: #EDF8FB;
    height: 100vh;
}
a {
  text-decoration-line: none
}
/* #all-products-left{
  text-align: center;
} */
.credentials{
   background: none;
   border: none;
   font-size : 17px;
   font-weight: 500;
}
#main-page{
  background-color : #21618c;
  opacity: 0.8;
  height: 100vh;
  color: white;
  overflow: hidden;
}
input[type='text'],input[type='date']{
  font-size: 13px;
}
#email, #password, #name, #role, #password2, #email2, #contact{
   border-radius: 17px;
   border: none;
}
#company-logo{
   padding-top: 3%;
   font-family: Brush Script Std;
   font-size: 55px;
   text-align: center;
}
#company-subheading{
  text-align: center;
  font-size: 13px;
  font-weight: 400;
}
#login-row-div, #login-col-div{
   height : 80%;
}
#login_btn, #signup_btn{
   border-radius : 17px;
   width: 100%;
   background-color:#21618c;
   opacity: 0.8;
   border: none;
}
#login-form-div{
   background-color: #e1ebf1;
   height: 100%;
   border-radius: 18px;
   color: black;
   
}
.react-grid-Grid, .react-grid-Main, .react-grid-Container{
  height : 100%;
}
.row-header{
    margin:0px auto;
    padding:0px auto;
  } 
.row-content {
  margin:0px auto;
  padding: 50px 0px 50px 0px;
  border-bottom: 1px ridge;
  min-height:400px;
}

.navbar NavItem NavLink {
  line-height: 50px;
} 
.header-link{
  color: #ECF0F1 !important;
}
.nav-item a.hover {
  opacity: 0.8 !important;
}
.navbar {
    padding-left: 0;
}
#header {
  background-color: #21618C;
  color: #ECF0F1;
  min-height: 45px;
  padding-top: 5px;
  padding-left: 20px;
}

h4.header {
  font-size: 2.5em;
}

.forms{
  margin-top: 2em;
}
/* .jumbotron {
  background-color: #21618C;
  color: #ECF0F1;
} */

#productmatrix
{
  height: 100vh;
}
#product_desc
  {
    background-color: #EDF8FB;
    border-top: 1px solid #ECF0F1;
    padding-top: 20px;
    min-height: 100%;
  }

#matrix{
    min-height: 60%;
  }
#notes
{
  margin-top: 15px;
}
textarea {
  min-height: 40%;
}
#description{
   margin-top: 30px;
   width : 70%;
   height : 100%;
}
#mat-submit-btn, #purchase-submit-btn{
    float : right
}
 #product-desc-right 
{
   min-height: 100%;
   background-color: #EDF8FB
}
#product-desc-mid
{
   height: 85vh;
   background-color: #EDF8FB;
}
#images {
  min-height: 30%;
  min-width: 100%;
  background-color: white;
  text-align: center;
  margin-top: 5px;
}
.image-div{
    width : 220px;
    height : 250px;
    /* border : 1px solid #00ACC1; */
}
.dropzone{
  height : 100%;
  width : 100%;
  border : 1px solid #00ACC1;
  padding-top : 47%;
}
.image-card{
   width : 250px;
   height : 250px;
   padding : 2%;
}
.close-btn-img{
   position: absolute;
   z-index: 10;
   margin-left : 100px;
   border : none;
   background: none;
   cursor: pointer;
   color : red;
}
.close-btn-img:hover{
    opacity : 0.8
}
#div1, #div2, #div3, #div4, #div5
{
  margin-top: 23px;
  background-color: white;
  min-height: 5%;
  padding-left: 10px;
}
#style-code-p{
  font-size: 1.5rem;
}
#style-code-div{
  min-height: 3rem;
}
 .dropdown-toggle{
  background-color: white;
  color: black;
}
.card {
  text-align: center;
}

#images-header, #card-header, #notes-heading{
  background-color: #21618C;
  color: #ECF0F1;
  text-align: center;
  margin-bottom: 0px;
}
.matrix-link
{
  color: black !important;
}
#matrix-div 
{
  background-color: white;
  min-height: 10vh;
  width: 100%
}
#process, #bom, #inventory, #totalcost, #bom-detail{
  background-color: white;
  min-height: 80vh;
}
#mid-tabs
{
  background-color: white;
  color:black;

}
.tab-content{
  min-height: 90%;
  height: 90%;
}
#mid-tabs-tabpane-matrix,  #color-size-matrix, .tab-pane
{
   min-height: 95%;
   height: 95%;
   background-color: white;
   overflow-y: auto;
   overflow-x: hidden;
}
nav a{
  color:black;
  
}
#table_div{
   margin-left: 10px;
   padding-left: 20px;
   width: 90%;
}
#new-product-btn,#new-matrix-btn
{
  float:right;
}
#new-product-btn{
   background-color: #00ACC1;
   color : white;
}
#outer-div-table
{
   width: 90%;
}
#all-product-right{
  background-color:  white;
}
#all-row-container{
  background-color:  white;
  border-radius: 10px;
  box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.08);
  height : 80vh;
  overflow: auto;
}
.mat_div_heading, .mat-button-div, .product-button-div{
  border-bottom: 1px solid #dee2e6;
  color : #616161;
}
.product-row{
  color : #616161;
}
.product-row-data:hover {
  background-color:#fafafa;
}
.product_div, #old-matrix, .mat_div
{
  width : 100%;
  min-height: 7vh;
  color: black; 
}
.product-a:hover, .all-material-a:hover{
   text-decoration-line: none;
}
.img-thumbnail{
   width : auto;
   height : 60px;
}
.product-cell, .materials-cell{
  text-align: center;
}
.product_div:hover, .mat_div:hover{
   cursor: pointer;
}
.k-input{
  width: 100%;
}
input{
  outline: none;
}
#logout {
  background-color: inherit
}
.material-page{
   height: 100%;
}
.images_div{
  border: 1px solid rgb(191, 192, 194);
  padding: 0;
  height: 40vh;
}
#material-page-row1{
  /* margin-top: 5%; */
   height : 40%
}
#material-page-details{
   overflow: auto;
   height: 100%
}
/* #material-page-row2{
  height : 60%
} */
.msku_image_div{
  border: 1px solid rgb(191, 192, 194);
  height: 80%;
  width: auto
}
.mat-images-div{
  border: 1px solid rgb(191, 192, 194);
  padding: 0;
  height: 40%;
  width: 70%
}
.materials-divs{
   width : 100%
}
.edit-btns {
   float : right
}
.sku_image,.msku_image{
  cursor: pointer;
}
.sku_image:hover, .msku_image:hover{
  opacity: 0.8
}
.react-grid-Container{
    overflow-x : auto
}
#all-materials{
   height : inherit;
}
#all-materials-right{
  overflow : auto
}
#material-right-div{
   background-color: white;
   height: 92vh;
}
#all-jobs-right, #inventory-div, #all-sales-right{
    background-color: white;
    height: 92vh;
}
.MuiInputBase-root{
   width : 100%;
}
#error_msg{
  color : red;
}
#success_msg{
  color : green;
}
#mat-image-p, #mat-desc-p{
  background: #21618C;
  color : white;
  text-align: center;
  margin-bottom: 0;
}
.tab-pane{
  height : 90%;
  
}
#product-filter-col, #vendor-filter-col, #material-filter-col{
  background-color:  white;
  border-radius: 10px;
  box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.08);
  margin-top : 3.5rem;
  overflow: auto;
  height : 80vh;
}
.filter-label{
  color : #616161;
  font-weight : 700;
  font-size: 18px;
}
#product-filter-col p, #vendor-filter-col p{
  text-align: center;
}
.page-item a{
  color: black;
  font-size: 12px;
}
.page-link{
  border: none;
}
.page-item.active .page-link{
  border-color: none;
  background: none;
  font-weight: 700;
  color: black;
}
::-webkit-scrollbar {
  width: 10px;
  height: 10px; 
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
  width: 6px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #e0e0e0;
  border-radius: 10px; 
  
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #bdbdbd; 
}

#search-bar{
  border-radius: 12px;
  font-size: 12px;
  padding-left: 2.375rem;
  border-color: #e0e0e0;

}
.more-label{
   cursor: pointer;
   color : #21618C;
   font-size: 14px;
}
.more-label:hover{
  transform: scale(1.2);
}
#all-materials-row, #inventory-table{
   border : 1px solid #dee2e6;
}
.new-material-btn, .new-product-btn{
   width: 94px;
   height: 27px;
   font-size: 12px;
   padding : 1px;
   background-color: #eeeeee;
   color : black;
   border-color: #eeeeee;
   border-radius: 10px;
}
.bulk-editor, .save-vendor{
  width: 94px;
  height: 2rem;
  font-size: 12px;
  padding : 1px;
  background-color: #00ACC1;
  color : white;
  border-color: #eeeeee;
  border-radius: 12px;
}
.back{
  width: 94px;
  height: 2rem;
  font-size: 12px;
  padding : 1px;
  background-color: grey;
  color : white;
  border-color: #eeeeee;
  border-radius: 12px;
}
#save-mat{
   float: right;
}
#search-icon{
   width : 60px;
   height : 4vh;
}
.product_div_heading, .product-a, .mat_div_heading, .all-material-a{
  width : 95%;
}
/* input[type="text"], select{
  border: none; 
  border-width: 0; 
  box-shadow: none;
}
.table td{
  border-right: 1px solid #eeeeee;
  width : 70px;
  padding: 1px
} */
.count-display{
   color : #21618C;
   font-weight: 700;
   font-size : 18px;
}
.show-count-span{
  font-size: 15px;
  border-left : 2px solid #21618C;
  height : 5px;
  color : #424242
}

.cost-label{
   width : 42%;
}
.cost-value{
  width : 58%
}
#company-dropdown{
  z-index: 100;
   position: absolute;
   background: white;
   border: 1px solid #e0e0e0;
   border-radius : 8px;
   box-shadow: 1px 8px 10px 2px rgba(0, 0, 0, 0.08);
   top: 48px;
   right: 18px;
   width : 250px;
}
#dropdown-div-1{
  padding : 0.6rem;
   text-align: center;
   font-size: 14px;
   color : #757575;
}
.dropdown-options{
   cursor: pointer;
   font-size: 16px;
   font-weight: 600;
   color : #616161;
}
.custom-checkbox{
  display: block;
 position: relative;
 padding-left: 27px;
 margin-bottom: 10px;
 cursor: pointer;
 font-size: 15px;
 -webkit-user-select: none;
 -moz-user-select: none;
 -ms-user-select: none;
 user-select: none;
}
.custom-checkbox input{
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkmark {
  position: absolute;
  margin-top: 3px;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: white;
  border : 1px solid #d3d3d3;
}
.custom-checkbox input:checked ~ .checkmark{
  background-color: #21618C;
}

.checkmark:after{
  content: "";
  position: absolute;
  display: none;
}

.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}

.custom-checkbox .checkmark:after {
  left: 4px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  line-height: 2rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
  width: 2.375rem;
  height: 2.375rem;
}
.create{
   height : 80vh;
   background: white;
   margin-top : 2.5rem;
   overflow: auto;
   border-radius: 10px;
   box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.08);
}